$(document).ready(function() {
    /* =====  Fancybox  ====== */
    $(".group").fancybox({

    });

    /* =====  MOBINE NAV TOGGLER  ====== */
    $('.offCanvasToggler').click(function(event) {
        event.preventDefault();
        $('.off-canvas-nav').toggleClass('active');
        $('.fullscreen-overlay').toggleClass('active');
        $('html, body').toggleClass('no-scrolling');
    });

    /* =====  Slider  ====== */
    $('.project-slider').slick({
        nextArrow: $('.slider-controls .next-slide'),
        prevArrow: $('.slider-controls .previous-slide'),
        arrows: true,
        dots: false
    });

    $('.mobile-slider-slides').slick({
        nextArrow: $('.slider-controls .next-slide'),
        prevArrow: $('.slider-controls .previous-slide'),
        arrows: true,
        dots: false,
    });

    $("body").on('contextmenu', 'img', function(e) {
        return false;
    });

    $('.isotope-grid').imagesLoaded(function() {
        $('.isotope-grid').masonry({
            itemSelector: '.grid-item',
            columnWidth: 80,
            gutter: 10
        });
    });

    $('.project').children().not('.spacer').hover(function() {
        $(this).siblings().toggleClass('hovered');
    });

    $('.scroll-arrow').click(function(event) {
        /* Act on the event */
        let arrow = $('.scroll-arrow').find("svg");
        if (arrow.hasClass("rotated") == true) {
            $(".content").animate({ scrollTop: 0 }, 400, 'swing');
            arrow.removeClass("rotated");
        } else {
            $(".content").animate({ scrollTop: $(".projecten").height() }, 400, 'swing');
        }

    });

    arrowBounce();

    $('.content').on('scroll', function() {
        if ($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
            $('.scroll-arrow').find("svg").addClass('rotated');
        }
    })
});

function arrowBounce() {
    $('.scroll-arrow').animate({ 'top': 120 }, 400, 'swing', function() {
        $('.scroll-arrow').animate({ 'top': 100 }, 600, 'swing', function() {
            arrowBounce();
        })
    });
}